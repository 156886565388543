<template>
  <div v-show="showReminder" class="landscape-reminder">
    <base-header :has-logo="true" :is-title-bold="true"></base-header>
    <div
      class="landscape-reminder-container d-flex flex-column align-items-center justify-content-center w-100"
    >
      <img width="240" :src="RotateDevice" class="img-rotate-device" />
      <div class="text-center">
        <span class="reminder-text text-28">{{ $t('請將裝置') }}</span>
        <span class="reminder-text text-primary text-28">{{
          ' ' + $t('橫向') + ' '
        }}</span>
        <span class="reminder-text text-28">{{ $t('使用') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import RotateDevice from '@/assets/img/rotate-device.png';
export default {
  name: 'LandscapeReminder',
  components: {
    BaseHeader: () => import('@/components/Base/BaseHeader'),
  },
  data() {
    return {
      RotateDevice,
    };
  },
  computed: {
    showReminder() {
      return !this.$device.isMobile && this.orientation === 'portrait';
    },
    orientation() {
      return this.$store.state.env.orientation;
    },
  },
};
</script>

<style lang="scss" scoped>
.landscape-reminder {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: $bgcontainer;
  .landscape-reminder-container {
    height: calc(100% - 56px);
  }
  .reminder-text {
    font-weight: bold;
    color: $font-grayscale-1;
  }
  .img-rotate-device {
    margin-bottom: 40px;
  }
}
</style>
